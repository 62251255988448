<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->
    <!-- Start 404 Page  -->
    <!-- <div class="error-page-inner bg_color--4">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">404!</h1>
              <h3 class="sub-title">Page not found</h3>
              <span>The page you were looking for could not be found.</span>
              <div class="error-button">
                <router-link class="btn-default" to="/"
                  >Back To Homepage</router-link>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div> -->
    <!-- End 404 Page  -->
    <!-- Start Breadcrump Area  -->
    <div
      class="
        breadcrumb-area
        rn-bg-color
        ptb--120
        bg_image bg_image--1
        error-page-inner
      "
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="breadcrumb-inner pt--100">
              <h1 class="white--text">404</h1>
              <ul class="page-list">
                <li
                  class="rn-breadcrumb-item"
                  v-for="(item, i) in breadcrumbs"
                  :key="i"
                >
                  <router-link :to="item.to">{{ item.text }}</router-link>
                </li>
              </ul>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      logo: require("../assets/images/social/freelancer.png"),
      breadcrumbs: [
        {
          text: "Page Not Found",
          to: "/",
        },
        {
          text: "Go Back Home",
          to: "/",
        },
      ],
    };
  },
};
</script>
