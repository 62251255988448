import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Missing from '../views/404.vue'


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        meta: {
            title: 'imdenny.io'
        },
        component: () =>
            import ('../views/imdennyio.vue')
    },
    {
        path: '/portfolio/:client',
        name: 'Portfolio',
        meta: {
            title: 'imdenny.io | Portfolio'
        },
        component: () =>
            import ('../views/imdennyio.vue'),
        beforeEnter: (to, from, next) => {
            function isValid (client) {
                // check if param is valid
                if(client == 'mesa-coatings' || client == "snowpunkz" || client == "pocketguide"
                    || client == "msgold" || client == "motorpark") {
                        return true
                    }
                return false;
            }
            if (!isValid(to.params.client)) {
                next({ name: 'not-found' });
            }
            next();
        }
    },
    {
        path: '*',
        component: Missing,
        name: 'not-found',
        meta: {
            title: 'imdenny.io | 404'
        },
    }
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        let scrollTo = 0

        if (to.hash) {
            scrollTo = to.hash
        } else if (savedPosition) {
            scrollTo = savedPosition.y
        }

        return goTo(scrollTo)
    },
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


export default router