<template>
  <ul
    class="
      d-flex
      justify-space-between
      liststyle">
    <li v-for="(social, i) in socialList" :key="i" class="mr--20">
      <div class="icon">
        <a :href="social.url"
          target="_blank"
          class="black--text"
          v-html="iconSvg(social.icon)">
        </a>
      </div>
    </li>
  </ul>
</template>
<script>
import feather from "feather-icons";
export default {
  data() {
    return {
      socialList: [
        {
          icon: "linkedin",
          url: "https://www.linkedin.com/in/denish-godara/",
        },
        {
          icon: "mail",
          url: "mailto:denishgodara942@gmail.com",
        },
        {
          icon: "github",
          url: "https://github.com/im-denny",
        },
      ]
    }
  },
  methods: {
    iconSvg(icon) {
      return feather.icons[icon].toSvg();
    },
  }
};
</script>
