<template>
  <v-footer padless>
    <v-card flat tile width="100%" class="text-center">
      <v-card-text>
        <div class="made-with-love">
          <p>Made with&nbsp;❤️&nbsp; by <span class="by">Denish</span></p><br><br>
        </div>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import feather from "feather-icons";
export default {
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
.made-with-love {
  text-align: center;
  background: transparent;
  font-family: Helvetica;
  p {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    font-size: 1.3em;
  }
  .by {
    font-weight: bold;
    a {
      text-decoration: none;
      color: inherit;
      &:hover {
        border-bottom: 1px solid;
      }
    }
  }
}
</style>
